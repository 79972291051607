@import './font/font.css';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "OpenSans-VariableFont", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: rgba(239, 244, 248, 1);
}

#root {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background: rgba(135, 147, 176, 0.50);
    border-radius: 10px;
}

::-webkit-scrollbar-button {
    display: none;
}

* {
    scrollbar-width: auto;
    scrollbar-color: rgba(135, 147, 176, 0.50) transparent;
}




